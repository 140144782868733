<template>
    <div>
        <v-btn>
            This is supposed to be a homepage at some point!
        </v-btn>
    </div>
</template>

<script>
export default {
    beforeCreate() {
        this.$router.push('/dashboard')
    }
}
</script>